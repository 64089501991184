@import '../../styles/customMediaQueries.css';

.sectionEventActivity {
  background: var(--marketplaceColorDark);
  color: var(--colorGrey50);
  overflow: hidden;
  /* Hide overflow to create a clean scroll effect */

  width: 100%;
  /* Ensure the section takes full width of the parent */
  height: auto;
  display: flex;
  flex-direction: row;

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 0 0.5rem 0.5rem;

    @media (--viewportMedium) {
      padding: 1rem 0 0.5rem 0.5rem;
    }

    .sectionHeading {
      font-size: 12px;
      font-weight: bold;

      padding: 0px;
      margin: 0;

      @media (--viewportMedium) {
        font-size: 14px;
      }
    }

    .totalEvents {
      font-size: 14px;
      font-weight: normal;

      padding: 0px;
      margin: 0;

      color: var(--colorGrey50);
    }
  }

  .marquee {
    background: red;
  }

  .eventActivities {
    margin: 0.5rem;
    overflow: hidden;
    /* Hide overflow to create a clean scroll effect */
    height: 100%;
    /* Set height for the marquee effect */

    /* Set height for the marquee effect */

    .eventActivity {
      width: 140px;
      /* border-left: 1px solid white; */
      padding-left: 8px;
      gap: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      text-align: center;

      .activityWrapper {
        flex: 1;
      }

      .activityType {
        text-transform: capitalize;

        font-size: 14px;
        margin: 0px;
        padding: 0px;
        line-height: 20px;

        color: var(--colorGrey50);
      }

      .activityDate,
      .locationPreference {
        font-size: 10px;

        margin: 0px;
        padding: 0px;

        color: var(--colorGrey50);
      }

    }
  }
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }

  /* Move left by 100% of the container */
}

.rfm-marquee {
  justify-content: space-evenly !important;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.iconInfo {
  margin: auto 10px;
  cursor: pointer;
}