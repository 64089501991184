@import '../../styles/customMediaQueries.css';

.sectionEventActivity {
  padding-bottom: 20px;
  background: var(--marketplaceColorDark);
  color: var(--colorGrey50);
  overflow: hidden; /* Hide overflow to create a clean scroll effect */
  
  width: 100%; /* Ensure the section takes full width of the parent */
  height: 100%;

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 2rem 1.5rem;

    @media (--viewportMedium) {
      padding: 1rem 2rem 1rem;
    }

    .sectionHeading {
      font-size: 14px;
      font-weight: bold;

      padding: 0px;
      margin: 0;
    }

    .totalEvents {
      font-size: 14px;
      font-weight: normal;

      padding: 0px;
      margin: 0;

      color: var(--colorGrey50);
    }
  }

  .eventActivities {
   
    overflow: hidden; /* Hide overflow to create a clean scroll effect */
    height: 100%; /* Set height for the marquee effect */

    .eventActivity {
      width: 140px;

      margin: auto 10px;

      .activityType {
        text-transform: capitalize;

        font-size: 14px;
        margin: 0px;
        padding: 0px;
        line-height: 20px;

        color: var(--colorGrey50);
      }

      .activityDate {
        font-size: 10px;

        margin: 0px;
        padding: 0px;

        color: var(--colorGrey50);
      }
    }
  }
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  } /* Move left by 100% of the container */
}

.rfm-marquee {
  justify-content: space-evenly !important;
}

.title{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconInfo {
  margin: auto 10px;
  cursor: pointer;
}